import qs from 'qs';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { sendContactus, setLayout, setPath, setPathLayout } from '../../actions/Utils';
import axiosInstance, { version } from '../../config/Api';


class Contactform extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validator:{
                name:'',
                email:'',
                concern:'',
                message:'',
            },
            message:'',
            message_type:'',
        };
        this.refs = React.createRef();
    }

    handleMessageClose = () => {
        this.setState({message:'',message_type:''})
    }

    handleSubmit = () => {
     

      
        const userFormData = {
            name:this.name.value,
            email:this.email.value,
            phone:this.phone.value,
            message:this.message.value,
        }
       
        const resetValidator = {
            name:'',
            email:'',
            phone:'',
            message:'',
        }
        var validation = {};
       
        if(userFormData.name == '') {
            validation.name = 'The name field is required.';
        }
        
        if(userFormData.email == '') {
            validation.email = 'The email field is required.';
        }

        if(userFormData.phone == '') {
            validation.phone = 'The phone field is required.';
        }
        if(userFormData.message == '') {
            validation.message = 'The message field is required.';
        }
       
        if(Object.keys(validation).length) {
            this.setState({validator:{...resetValidator,...validation},message:''});
            
        }
        else {
        

            axiosInstance.post(version+'sendContactus',qs.stringify(userFormData)).then((res) => {

               
                this.name.value = '';
                this.email.value = '';
                this.phone.value = '';
                this.message.value = '';
                this.setState({message:res.data.success,message_type:'success',validator:{...resetValidator}})
            })
            .catch(error => {
               
             
                if(typeof error.response.data.errors != "undefined") {
                    for (const [key, value] of Object.entries(error.response.data.errors)) {
                       validation[key] = value;
                    }
                    this.setState({validator:validation})
                }
                else if(error.response) {
                   var html = '';
                    if(Object.keys(error.response.data).length) {

                        for (const [key, value] of Object.entries(error.response.data)) {
                            //console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                            html += `${value}`;
                        }
                        
                    }
                    // if(Object.keys(error.response.data).length) {
                    //     for (const [key, value] of Object.entries(error.response.data)) {
                    //         //console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                    //         html += `${value}`;
                    //     }
                        
                    // }
                  
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the 
                    // browser and an instance of
                    // http.ClientRequest in node.js
                    // console.log('error.request ',error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                }
                if(html){
                    this.setState({message:html,message_type:'danger',validator:{...resetValidator}})
                }
                
            })
        }
        
       
    }

  render() {
      
    const contents = this.props.data.contents;
    const {validator} = this.state;
    var description =contents?contents.description.replace(/<\/?[^>]+(>|$)/g, ""):'';
    return (
        <Fragment>
           
          	
           
                <div className="contact-from">
                    <h3>{contents? contents.short_description:''}</h3>
                    <p>
                        {description}
                    </p>

                    <div className="contact-main">
                    <div className="row">
                        {this.state.message !='' && this.state.message_type =='success' && <div className="col-sm-12" >
                                <div className="alert  alert-success alert-dismissible fade show" role="alert">
                                    {this.state.message}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>this.handleMessageClose()}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>}
                            {this.state.message !='' && this.state.message_type =='danger' && <div className="col-sm-12">
                                <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                                    {this.state.message}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>this.handleMessageClose()}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>}
                    </div>
                    

                        <form>
                            <div className="form-group">
                             <label>Full Name <span>*</span></label>
                                <input type="text" ref={(input) => this.name = input} className={validator.name?'form-control from-style input-error':'form-control from-style'} placeholder="Full Name"/>
                                
                                {validator.name && <span className="help-block form-text text-danger">{validator.name}</span>}
                            </div>
                            
                            <div className="form-group">
                            <label>Email <span>*</span></label>

                                <input type="email" ref={(input) => this.email = input} className={validator.email?'form-control from-style input-error':'form-control from-style'} placeholder="Email" />
                                {validator.email && <span className="help-block form-text text-danger">{validator.email}</span>}
                            </div>
                            <div className="form-group">
                            <label>Phone Number <span>*</span></label>

                                <input type="text" ref={(input) => this.phone = input} className={validator.phone?'form-control from-style input-error':'form-control from-style'} placeholder="Phone Number" />
                                {validator.phone && <span className="help-block form-text text-danger">{validator.phone}</span>}
                            </div>
                         
                            
                            <div className="form-group">
                            <label>Message <span>*</span></label>
                                <textarea className={validator.message?'form-control from-style input-error':'form-control from-style'}  ref={(input) => this.message = input} placeholder="Mesagge" rows="6"></textarea>
                            
                                {validator.message && <span className="help-block form-text text-danger">{validator.message}</span>}
                            </div>
                        
                    </form>
                    </div>
                    

                    <div className="submit">

                          <input type="button" value="Submit" className="btn submit-button" name="submit" onClick={()=>this.handleSubmit()}/>
                          <span> <i className="fa fa-phone-alt"></i>Or, Call  <a href="#">88 09 612 612 100</a></span>

                        </div>
                </div>
              
        </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
    sendContactus : (requestData)=>{dispatch(sendContactus(requestData))},
})

export default connect(null,mapDispatchToProps)(Contactform);