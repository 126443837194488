// import logo from './logo.svg';
import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router, Route, Switch
} from "react-router-dom";

import Cookies from 'js-cookie';
import * as qs from 'query-string';

import { addPushWebToken, getRoutes, getSetting } from './actions/Utils';
import './App.css';
import Footer from './components/common/Footer';
import CommonHeader from './components/common/header/Commonheader';
import PhotoGallery from './components/gallery/PhotoGallery';
import VideoGallery from './components/gallery/VideoGallery';


import Member from './layout/MemberLayout';

import { base_path } from './config/Api';
import HomeLayout from './layout/Home';
// import Login from './layout/Login';
import SignupPolicy from './components/register/SignupPolicy';
import Login from './components/register/Login';
import NewsLayout from './layout/News';
import PageLayout from './layout/Page';
import Register from './layout/Register';
import SearchLayout from './layout/SearchLayout';
import CommonLanding from './page/CommonLanding';
import Contactus from './layout/Contactus';
import PremiumCalculator from './layout/PremiumCalculator';
import ProductDetails from './components/product/ProductDetails';
import OfficeBranch from './components/branch/Branch';
import PolicyBuy from './components/customer/PolicyBuy';

// Publication component end
import configureStore from './store/Configurstore';


import ForgotPassword from './layout/ForgotPassword';
import ResetPassword from './layout/ResetPassword';

// import firebase from './config/Firebase';
// import { getMessaging, getToken } from "firebase/messaging";

// import firebase from 'firebase/compat/app';
// import { getMessaging, getToken ,onMessage} from "firebase/messaging";

// import Notifications from './components/Notifications/Notifications';

import Htmlhelmet from './components/common/HtmlHelmet';
import Paydirectstepone from './components/paydirect/Paydirectstepone';
import Paydirectsteptwo from './components/paydirect/Paydirectsteptwo';
import Paydirectstepthree from './components/paydirect/Paydirectstepthree';
import Paydirectstepfour from './components/paydirect/Paydirectstepfour';

// web user panel
import Dashboard from './components/webuserpanel/Dashboard';
import PolicyDetails from './components/webuserpanel/PolicyDetails';
import ChangePassword from './components/webuserpanel/ChangePassword';
import PaymentInvoice from './components/webuserpanel/PaymentInvoice';

const store = configureStore();

// const messagingab = getMessaging()
// onMessage(messagingab, (payload) => {
//   console.log("message firebsae payload",payload);
// })



class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      utils: store.getState().utils,
      routemenu: store.getState().utils.routemenu,
      pubroutemenu: store.getState().utils.pubroutemenu,
      notificaitonshow: false,
      notification: {
        title: '',
        body: ''
      }
    };


  }

  handlePushNotification = (token) => {
    var requestData = {
      device_id: token
    }
    store.dispatch(addPushWebToken(requestData)).then((res) => {
      // console.log("handle push notification", res);
    });
  }

  

  componentDidMount = () => {

    store.dispatch(getRoutes()).then((res) => {
      // console.log(" route data = ",res);
      this.setState({ routemenu: res })
    });
    // store.dispatch(getPubRoutes()).then((res) => {
    //   // console.log(" route data = ",res);
    //   this.setState({ pubroutemenu: res })
    // });

    store.dispatch(getSetting()).then((res) => {
      // console.log('Footer data = ',res);
    });



  }

  render() {
    // console.log('app routemenu js file = ',this.state.routemenu);
    const routemenu = this.state.routemenu;
    const pubroutemenu = [];//this.state.pubroutemenu;

    var site = base_path.split("/");
    // let pathName = this.state.utils.path;
// console.log("site",site);
    var basename = "/";
    if (site.includes("www.tiyait.com") || site.includes("30002")) {
      basename = "/";
    }
    else if (site.includes("dynamic")) {
      basename = "/dynamic/";
    }
    
    else {
      basename = "/";
    }
    //  console.log(" Hello process.env.PUBLIC_URL =",process.env.PUBLIC_URL);
    // console.log('basename =',basename);
    return (
      <Router basename={basename}>
        {/* <Router basename={'/publication/'}> */}
        <Fragment>
         
          {/* <Notifications /> */}
          <CommonHeader />
          <Htmlhelmet />
          <Switch>
            <Route exact path={`/`} >
              <HomeLayout />
            </Route>


            <Route path={`${process.env.PUBLIC_URL}/login`}>
              <Login />
            </Route>
            <Route path={`${process.env.PUBLIC_URL}/signup`}>
              <SignupPolicy />
            </Route>
            <Route path={`${process.env.PUBLIC_URL}/register`}>
              <Register />
            </Route>

            <Route path={`${process.env.PUBLIC_URL}/paydirect-step-1`}>
              <Paydirectstepone />
            </Route>
            <Route path={`${process.env.PUBLIC_URL}/paydirect-step-2`}>
              <Paydirectsteptwo />
            </Route>
            <Route path={`${process.env.PUBLIC_URL}/payment-step-1`}>
              <Paydirectstepthree />
            </Route>
            <Route path={`${process.env.PUBLIC_URL}/payment-step-2`}>
              <Paydirectstepfour />
            </Route>


            <Route exact={true} path={`${process.env.PUBLIC_URL}/dashboard`}>
              <Dashboard />
            </Route>
            <Route path={`${process.env.PUBLIC_URL}/dashboard/policy-details/:id`}>
              <PolicyDetails />
            </Route>

            <Route path={`${process.env.PUBLIC_URL}/dashboard/change-password`}>
              <ChangePassword />
            </Route>

            <Route path={`${process.env.PUBLIC_URL}/dashboard/policyinvoice/:id`}>
              <PaymentInvoice />
            </Route>
            

            <Route path={`${process.env.PUBLIC_URL}/page/:slug`}>
              <PageLayout />
            </Route>

            <Route path={`${process.env.PUBLIC_URL}/product/:slug`}>
              <ProductDetails />
            </Route>
            

            <Route path={`${process.env.PUBLIC_URL}/news/:slug`}>
              <NewsLayout />
            </Route>


            <Route path={`${process.env.PUBLIC_URL}/search/:slug`}>
              <SearchLayout />
            </Route>

            <Route exact path={`${process.env.PUBLIC_URL}/videos/:id/album`}>
              <VideoGallery />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/photos/:id/album`}>
              <PhotoGallery />
            </Route>

           


            
            <Route exact path={`${process.env.PUBLIC_URL}/forgot-password`}>
              <ForgotPassword />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/reset-password`}>
              <ResetPassword />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/policy-buy`}>
              <PolicyBuy />
            </Route>
            
         
           


            {routemenu.map((menu, index, { length }) => {
              if (menu.layout) {
                //if(index + 1 === length) { 
                if (menu.layout.name.toLowerCase() == 'home') {
                  return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <HomeLayout />} />
                }
                if (menu.layout.name.toLowerCase() == 'common landing') {

                  return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <CommonLanding />} />

                }
                if (menu.layout.name.toLowerCase() == 'gallery') {
                  return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <CommonLanding />} />
                }
                // if (menu.layout.name.toLowerCase() == 'contact us') {
                //   return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <Contactus />} />
                // }
                if (menu.layout.name.toLowerCase() == 'premium calculator') {
                  return <Route key={menu.id} exact path={`${menu.slug}`} render={() => <PremiumCalculator />} />
                }
                if (menu.layout.name.toLowerCase() == 'branch') {
                  return <Route key={menu.id} exact path={`${menu.slug}`} render={() => <OfficeBranch />} />
                }

                if (menu.layout.name.toLowerCase() == 'member') {
                  // console.log(this.props);
                  return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <Member />} />
                }
                
              }
              else {
                return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => '404 NOT FOUND'} />
              }

            })}

          </Switch>
          <Footer />
          
        </Fragment>
      </Router>
    );
  }
}

export default App;

