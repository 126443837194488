import axios from 'axios';

var token = localStorage.getItem("token") ? 'Token ' + localStorage.getItem("token") : '';
// export default axios.create({
//   // baseURL: `http://jsonplaceholder.typicode.com/`
//   baseURL: `http://127.0.0.1:8000/`,
//   headers: {'Authorization': token}
// });


const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = 'https://licbangladesh.com/licadmin/api/';
//axiosInstance.defaults.baseURL = `http://licadmin.com:8080/api/`;

export const casInstance = axios.create();
casInstance.defaults.baseURL = 'https://api.icab.org.bd/api/';
export const caversion = 'v1/';
casInstance.defaults.headers.common['Authorization'] = 'Basic Y2xpZW50LXdlYjpVQTAxMjM0NTY=';

// axiosInstance.defaults.headers.common['Authorization'] = token;
// axiosInstance.defaults.headers.common['User-ID'] = '';

export const version = 'v1/';
export const imagePath = 'v1/';


export const processMaker = axios.create();
processMaker.defaults.baseURL = 'https://studentservices.icab.org.bd/sysworkflow/en/neoclassic/services/soap2';
processMaker.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const image_url = 'https://licbangladesh.com/licadmin/';
//export const image_url = `http://licadmin.com:8080/`;


export const base_path = '/';
//export const base_path = 'https://licbangladesh.com/dynamic/';
//export const host_name = 'https://licbangladesh.com/dynamic/';
export const host_name = 'https://licbangladesh.com/';
//export const host_name = `http://licbangladesh.lic:8080/`;

export const encryption_salt = "LIC@2022";
export const google_recaptcha_site_key = '6Le2E5gaAAAAADPCWtOtzWkaBwQ1jAlPtjP7XRuK';

export default axiosInstance;


export const axiosPorichoy = axios.create();
axiosPorichoy.defaults.baseURL = 'https://api.porichoybd.com/api/kyc/';
// axiosPorichoy.defaults.baseURL = 'https://api.porichoybd.com/sandbox-api/';
axiosPorichoy.defaults.headers.common['x-api-key'] = '92673172-a9e7-4d92-9324-5f3821927fb0';
axiosPorichoy.defaults.headers.common['Content-Type'] = 'application/json';